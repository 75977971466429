<template>
  <div>
    <base-template :submit-flag="dataForm.describe.length>10" :show-btn="true" @submit="subFeedback">
      <h6 class="title">{{ $t('settings.feedback') }}</h6>
      <van-field
        v-model="dataForm.describe"
        class="feed-back-box"
        type="textarea"
        maxlength="500"
        :placeholder="$t('userCenter.feedBackHolder')"
        show-word-limit
        rows="21"
      />
      <!-- <h6 class="title">Tolong berikan gambar</h6>
      <ul class="img-box">
        <li v-for="(item,index) in photoList" :key="index" class="img-item">
          <img :src="item.url" alt="">
          <span v-if="item.url" class="delete-icon" @click="deletePhoto()" />
        </li>
      </ul> -->
    </base-template>
  </div>
</template>

<script>
import { subFeedback } from '@/api/axios'
export default {
  data() {
    return {
      photoList: [{}],
      dataForm: {
        question: '',
        describe: '',
        photo1: '',
        photo2: '',
        photo3: ''
      }
    }
  },
  methods: {
    async subFeedback() {
      const { inyimbo } = await subFeedback(this.dataForm) || {}
      if (inyimbo + '' === '200') {
        this.$toast({
          type: 'success',
          message: this.$t('public.submitSuccess'),
          onClose: () => {
            this.$H5Object.goBack()
          }
        })
      }
    },
    deletePhoto() {
      //
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  background: #fff;
}
.title{
  font-size: 32px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #1F1F39;
  line-height: 38px;
  margin-top: 54px;
  margin-bottom: 30px;
}
.feed-back-box{
  width: 100%;
  // height: 520px;
  background: #FFFFFF;
  padding: 30px;
  padding-bottom: 0;
  font-size: 28px;
  border-radius: 20px;
  border: 1px dashed #979797;
}
:deep(.btn-box){
  position: fixed;
  bottom: 82px;
  width: 100%;
  left: 0;
  .submit-btn{
    margin-bottom: 0;
  }
}
:deep(.van-field__value){
  padding-bottom: 60px;
}
:deep(.van-field__word-limit){
  font-size: 24px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #B8B8D2;
}

.img-box{
  display: flex;
  .img-item{
    width: 190px;
    height: 190px;
    border-radius: 20px;
    border: 1px dashed #979797;
    margin-right: 50px;
    background: url('~@/assets/images/userCenter/add-photo-icon.png') no-repeat center center;
    background-size: 50px 50px;
    position: relative;
    .delete-icon{
      width: 38px;
      height: 38px;
      position: absolute;
      top: 6px;
      right: 6px;
      background: url('~@/assets/images/userCenter/delete-photo-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
}
</style>
<style>
.toast-width{
  width: 50%;
}
.toast-width .van-toast__text{
  line-height: 34px;
}
</style>
